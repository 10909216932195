import { Pagination, useListContext } from 'react-admin';

import { NonClickableContainer, Container } from './ParcelsPagination.styles';

const ParcelsPagination = () => {
    const { isFetching } = useListContext();

    return (
        <Container>
            {isFetching && <NonClickableContainer />}
            <Pagination style={{ color: isFetching ? '#888' : '#000' }} />
        </Container>
    );
};

export default ParcelsPagination;

import type { FC } from 'react';
import { useTranslate, TextInput, TextField, Datagrid, FunctionField } from 'react-admin';

import { ResourceKey } from 'constants/index';
import { ListWithPermissions, useHasAccess } from 'modules/auth';
import { SeoHead } from 'modules/ui';
import { PermissionAction } from 'modules/roles';

import type { Lane } from 'modules/lanes/types';

import { LaneEditButton } from '../LaneEditButton';
import { LaneDeleteButton } from '../LaneDeleteButton';

const LanesList: FC = props => {
    const translate = useTranslate();
    const hasDetailAccess = useHasAccess(ResourceKey.LANES, PermissionAction.DETAIL);

    return (
        <>
            <SeoHead title={translate('lanes.list.title')} />
            <ListWithPermissions<Lane>
                {...props}
                title={translate('lanes.list.title')}
                filters={[<TextInput label={translate('lanes.list.search')} source="q" alwaysOn />]}
                sort={{ field: 'name', order: 'ASC' }}
            >
                <Datagrid rowClick={hasDetailAccess ? 'show' : undefined} bulkActionButtons={false}>
                    <TextField source="name" label={translate('lanes.name')} />
                    <TextField source="description" label={translate('lanes.description')} />
                    <FunctionField<Lane>
                        label={translate('lanes.type')}
                        render={lane => (lane ? translate(`lanes.type.${lane.type}`) : null)}
                    />
                    <TextField source="depotDisplayName" label={translate('lanes.depot')} />
                    <LaneEditButton />
                    <LaneDeleteButton />
                </Datagrid>
            </ListWithPermissions>
        </>
    );
};

export default LanesList;

import { useTranslate, TextField, Datagrid, TextInput, SelectInput } from 'react-admin';

import { ResourceKey } from 'constants/index';
import { SeoHead } from 'modules/ui';

import { EditButtonWithPermissions, ListWithPermissions, useHasAccess } from 'modules/auth';
import { PermissionAction } from 'modules/roles';

export interface WarehousesListProps {}

export const WarehousesList = (props: WarehousesListProps) => {
    const translate = useTranslate();
    const hasDetailAccess = useHasAccess(ResourceKey.USER, PermissionAction.DETAIL);

    return (
        <>
            <SeoHead title={translate('warehouses.list.title')} />
            <ListWithPermissions
                {...props}
                title={translate('warehouses.list.title')}
                filters={[
                    <TextInput label={translate('warehouse.name')} source="name" alwaysOn />,
                    <TextInput label={translate('warehouse.identifier')} source="identifier" alwaysOn />,
                    <TextInput label={translate('warehouse.address')} source="address" alwaysOn />,
                    <SelectInput
                        source="numberOfPartners"
                        label={translate('warehouse.numberOfPartners')}
                        choices={[
                            { id: '=0', name: '0' },
                            { id: '=1', name: '1' },
                            { id: '>1', name: '2+' },
                        ]}
                        alwaysOn
                        sx={{ width: 200 }}
                    />,
                    <SelectInput
                        source="laneCountFilter"
                        label={translate('warehouse.numberOfLanes')}
                        choices={[
                            { id: '=0', name: 'warehouse.lanes.none' },
                            { id: '=1', name: 'warehouse.lanes.one' },
                            { id: '>1', name: 'warehouse.lanes.more' },
                        ]}
                        alwaysOn
                    />,
                ]}
            >
                <Datagrid rowClick={hasDetailAccess ? 'show' : undefined} bulkActionButtons={false}>
                    <TextField source="name" label={translate('warehouse.name')} />
                    <TextField source="identifier" label={translate('warehouse.identifier')} />
                    <TextField source="address" label={translate('warehouse.address')} />
                    <TextField source="numberOfPartners" label={translate('warehouse.numberOfPartners')} />
                    <TextField source="laneCount" label={translate('warehouse.lanes')} />
                    <EditButtonWithPermissions />
                </Datagrid>
            </ListWithPermissions>
        </>
    );
};

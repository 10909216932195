import { styled } from '@mui/material';

export const NonClickableContainer = styled('div')(({ theme }) => ({
    position: 'absolute',
    bottom: 0,
    right: 0,
    backgroundColor: 'transparent',
    height: 50,
    width: 90,
    zIndex: 10,
}));

export const Container = styled('div')(({ theme }) => ({
    position: 'relative',
}));

import { isLocalhost } from 'constants/env';

const config = {
    api: {
        url: 'https://api-development.boxnow.gr/api/v1',
    },
    adminApi: {
        url: 'https://api-development.boxnow.gr/ra-api',
    },
    sentry: {
        dsn: 'https://4562ab40497b4f86a90b62334ca15ab0@o1006836.ingest.sentry.io/5980664',
    },
    dev: {
        // If the sms code is avail. in the response `/auth-sessions:codeGet` then the user will be automatically logged in
        // This is true on the development environment
        autoSignInWithSmsCode: isLocalhost,
    },
} as const;

export type EnvConfig = typeof config;

export default config;

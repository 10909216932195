import { useSimpleFormIterator } from 'react-admin';

import { useWarehouseEdit } from './useWarehouseEdit';

export const usePartnerWarehouseDelete = (warehouseIndex: number) => {
    const { remove } = useSimpleFormIterator();
    const [editedWarehouse, setEditedWarehouse] = useWarehouseEdit();

    return function handleDelete() {
        remove(warehouseIndex);

        if (editedWarehouse !== null && warehouseIndex < editedWarehouse) {
            setEditedWarehouse(editedWarehouse - 1);
        }
    };
};

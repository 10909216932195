import { AutocompleteInput, useChoicesContext, useTranslate } from 'react-admin';
import type { AutocompleteInputProps } from 'react-admin';

import { OptionWarehouse, useOptionWarehouse } from 'modules/ui';

import type { WarehouseData } from '../../types';

// Option must be "memoized" https://github.com/marmelab/react-admin/issues/8545
const optionText = <OptionWarehouse />;

export interface AutocompleteWarehouseInputProps extends AutocompleteInputProps {
    addWarehouse: (item: WarehouseData) => void;
}

export const AutocompleteWarehouseInput = ({ addWarehouse, ...props }: AutocompleteWarehouseInputProps) => {
    const translate = useTranslate();
    const { inputText, matchSuggestion } = useOptionWarehouse();

    const choices = useChoicesContext<WarehouseData>();

    const onSelect = (id: WarehouseData['id']) => {
        const existingWarehouse = choices.allChoices.find(choice => choice.id === id);
        addWarehouse({ ...existingWarehouse, shared: true });
    };

    return (
        <AutocompleteInput
            label={translate('partners.warehouse.addExisting')}
            optionText={optionText}
            inputText={inputText}
            matchSuggestion={matchSuggestion}
            fullWidth
            filterToQuery={searchText => ({
                q: searchText,
            })}
            onChange={onSelect}
            {...props}
        />
    );
};

import { useSimpleFormIteratorItem } from 'react-admin';
import { useFormContext } from 'react-hook-form';

import { createWarehouseSourcePrefix } from '../../utils';
import {
    usePartnerWarehouse,
    usePartnerWarehouseDelete,
    usePartnerWarehouseDiscard,
    useWarehouseEdit,
} from '../../hooks';

import { WarehouseCard } from '../WarehouseCard';
import { WarehouseForm } from '../WarehouseForm';

export const Warehouse = () => {
    const { trigger } = useFormContext();
    const [editedWarehouse, setEditedWarehouse] = useWarehouseEdit();

    const { index: warehouseIndex } = useSimpleFormIteratorItem();
    const sourcePrefix = createWarehouseSourcePrefix(warehouseIndex);

    const { data, isNew, handleSubmit, handleCancel } = usePartnerWarehouse(warehouseIndex);
    const handleDelete = usePartnerWarehouseDelete(warehouseIndex);
    const handleDiscard = usePartnerWarehouseDiscard(warehouseIndex);

    const setFormMode = () => setEditedWarehouse(warehouseIndex);

    return editedWarehouse === warehouseIndex ? (
        <WarehouseForm
            data={data}
            sourcePrefix={sourcePrefix}
            isNewWarehouse={isNew}
            onSubmit={handleSubmit}
            onCancelSubmit={handleCancel}
            onDiscard={handleDiscard}
            trigger={trigger}
        />
    ) : (
        <WarehouseCard data={data} sourcePrefix={sourcePrefix} onEdit={setFormMode} onDelete={handleDelete} />
    );
};
